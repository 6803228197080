import { Header } from 'components/molecules';
import { ListUserTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administration' }];

const ListUserPage = () => {
  return (
    <>
      <Header
        title="Users"
        subtitle="Create, edit, list and delete users"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListUserTemplate />
    </>
  );
};

export default withAuth(ListUserPage);
