import { Header } from 'components/molecules';
import { EditUserTemplate, EditOptionsTemplate  } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administration' }];

const EditUserPage = () => {
  return (
    <>
      <Header
        title="Option"
        subtitle="Edit option"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <EditOptionsTemplate />
    </>
  );
};

export default withAuth(EditUserPage);
