import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  InputPassword,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { CREATE_OPTIONS, CREATE_USER } from 'const';
import { states } from 'const/const';
import { useCustomerContext } from 'contexts';
import { useGetTable, useYupResolve } from 'hooks';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomerOption, Role, User, TypeOption, Option } from 'types';
import { getErrorsFromBackend } from 'utils';
import {
  fetchRoles,
  fetchCustomers,
  fetchTypeOptions,
} from './CreateOptionsTemplate.const';
import CreateOptionsTemplateLoading from './CreateOptionsTemplate.loading';
import {
  CreateOptionsSchema,
  CreateOptionsType,
} from './CreateOptionsTemplate.schema';

const CreateUserTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { domain } = useCustomerContext();
  console.log(loading);
  const { data: customers, loading: loadingRoles } = useGetTable<
    CustomerOption[]
  >(fetchCustomers, [{}]);

  const { data: typeOptions, loading: loadingtypeOptions } = useGetTable<
    TypeOption[]
  >(fetchTypeOptions, [{}]);

  const validation = useCallback(
    async (data: any) => {
      const currentSchema = CreateOptionsSchema(domain);

      const values = await currentSchema.validate(data, {
        abortEarly: false,
      });

      return values;
    },
    [domain],
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CreateOptionsType>({
    resolver: useYupResolve(validation),
    defaultValues: {
      enabled: 'true',
    },
  });

  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/option');
  };

  const onSubmit = async (createOptionsForm: CreateOptionsType) => {
    try {
      setLoading(true);
      const { ...form } = createOptionsForm;
      //   const plainRoles = roles?.split(',').filter((item) => item !== '');
      const enabled = form.enabled === 'true';
      await axios.post<Option>(CREATE_OPTIONS, {
        ...form,
        enabled,
        // roles: plainRoles,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      //   const { isGeneral, errorOptions } = getErrorsFromBackend<CreateOptionsType>(
      //     error,
      //     {
      //       username: {
      //         Unique: 'Este usuario ya existe, pruebe con otro nuevamente',
      //       },
      //     },
      //   );

      //   if (isGeneral) {
      //     setError(...errorOptions);
      //     setOpen('error');
      //   } else
      //     errorOptions.forEach((error) => {
      //       setError(...error);
      //     });
    }
  };
  return (
    <Paper>
      {!loadingRoles ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={2}>
              <Input
                label="Order"
                fullWidth
                required
                {...register('order')}
                // error={!!errors.order}
                // helperText={errors.order ? errors.order.message : `Error`}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Attempt"
                fullWidth
                required
                {...register('attempt')}
                // error={!!errors.attempt}
                // helperText={errors.attempt ? errors.attempt.message : `Error`}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Status"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Message"
                fullWidth
                required
                {...register('message')}
                // error={!!errors.message}
                // helperText={errors.message ? errors.message.message : `Error`}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label="Value"
                fullWidth
                required
                {...register('value')}
                // error={!!errors.value}
                // helperText={errors.value ? errors.value.message : `Error`}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="customer"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Customer"
                      fullWidth
                      required
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.customer}
                      helperText={errors.customer && errors.customer.message}
                    >
                      {customers &&
                        customers.map(({ id, name }) => (
                          <MenuItem key={id} label={`${name}`} value={`${id}`}>
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="typeOption"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Type"
                      fullWidth
                      required
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.typeOption}
                      helperText={
                        errors.typeOption && errors.typeOption.message
                      }
                    >
                      {typeOptions &&
                        typeOptions.map(({ id, code }) => (
                          <MenuItem key={id} label={`${code}`} value={`${id}`}>
                            {code}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/option"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Create
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'The option was created successfully'}
            {open === 'error' && 'An error occurred, please try again'}
          </SnackBar>
        </form>
      ) : (
        <CreateOptionsTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateUserTemplate;
