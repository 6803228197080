import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_USERS, GET_OPTIONS } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { PaginationType, User, Option } from 'types';
import { ListOptionTemplateFilterType } from './ListOptionsTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 10,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Option> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListOptionTemplateFilter: ListOptionTemplateFilterType = {
  message: undefined,
};

export const getColumns: (
  onDelete: (id: number) => void,
  onActive: (id: number) => void,
) => columnType<Option>[] = (onDelete, onActive) => [
  {
    field: 'order',
    headerName: 'Order',
    flex: '5',
    exportField: true,
    render: ({ order }) => order,
  
  },
  {
    field: 'message',
    table: 'message',
    headerName: 'Title',
    flex: '4',
    exportField: true,
    render: ({ message }) => message,
  },
  {
    field: 'typeOption',
    headerName: 'Type',
    flex: '5',
    exportField: true,
    render: ({ typeOption }) => typeOption.code,
  },
  {
    field: 'customer',
    headerName: 'Customer',
    flex: '5',
    exportField: true,
    render: ({ customer }) => customer.name,
  },
  
  {
    field: 'enabled',
    headerName: 'Status',
    flex: '1',
    exportField: true,
    render: ({ enabled }) => (enabled ? 'Enable' : 'Disable'),
  },
  {
    field: 'actions',
    headerName: 'Acciones',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: ({ id, enabled }) => (
      <>
        <IconButton
          info={enabled ? 'Disable option' : 'Enable option'}
          color={enabled ? 'success' : 'grey'}
          onClick={() => onActive(id)}
        >
          <AiOutlineCheck />
        </IconButton>
        <IconButton
          info="Edit Option"
          positionEdge="start"
          color="grey"
          href={`/pages/management/option/${id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Delete option"
          positionEdge="start"
          color="error"
          onClick={() => onDelete(id)}
        >
          <AiOutlineDelete />
        </IconButton>
      </>
    ),
  },
];

export const fetchProcess = (
  { filterModel, sortModel, paginationModel }: tableModelType<Option>,
  config?: AxiosRequestConfig<Option>,
) => {
  return axios.get<PaginationType<Option>>(
    `${GET_OPTIONS}?${
      filterModel && filterModel.length > 0
        ? `filter=${JSON.stringify(filterModel)}&`
        : ''
    }${
      paginationModel
        ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
        : ''
    }`,
    config,
  );
};
