import styled from 'styled-components';

export const Root = styled.div<{ $open?: boolean }>`
  color: inherit;
  width: 100%;
  box-sizing: border-box;
  background-color: inherit;
  font: inherit;
  max-height: ${({ $open }) => ($open ? '100px' : '0px')};
  overflow-y: auto;
  transition: all 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;
