import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { UPDATE_USER, UPDATE_OPTIONS } from 'const';
import { states } from 'const/const';
import { useGetTable } from 'hooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerOption, TypeOption, Option } from 'types';
import { cleanInvalidObj, getErrorsFromBackend } from 'utils';
import {
  fetchCustomers,
  fetchTypeOptions,
  fetchOptions,
} from './EditOptionsTemplate.const';
import EditOptionsTemplateLoading from './EditOptionsTemplate.loading';
import {
  EditOptionsSchema,
  EditOptionsType,
} from './EditOptionsTemplate.schema';

const EditOptionsTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(loading);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<EditOptionsType>({
    resolver: yupResolver(EditOptionsSchema),
    defaultValues: {
      enabled: '',
    },
  });

  const { data: customers, loading: loadingRoles } = useGetTable<
    CustomerOption[]
  >(fetchCustomers, [{}]);

  const { data: typeOptions, loading: loadingtypeOptions } = useGetTable<
    TypeOption[]
  >(fetchTypeOptions, [{}]);

  // const { data: roles, loading: loadingR } = useGetTable<Role[]>(
  //   fetchRoles,
  //   [{}],
  // );

  const { data } = useGetTable<Option, { id: string }>(fetchOptions, {
    id: id as string,
  });

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/option');
  };

  const onSubmit = async (loginForm: EditOptionsType) => {
    try {
      setLoading(true);
      const { ...form } = loginForm;
      console.log('********************FORM:', form);
      // const plainRoles = roles?.split(',').filter((item) => item !== '');
      const enabled = form.enabled === 'true';

      //TO DO: array to obj
      const data = cleanInvalidObj(form);

      await axios.put<Option>(UPDATE_OPTIONS.replace(':id', id as string), {
        ...data,
        enabled,
      });

      console.log(data);
      setLoading(false);
      setOpen('success');
    } catch (error) {
      console.log('Passsoooo un Error');
      setLoading(false);
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditOptionsType>(error);

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };

  useEffect(() => {
    if (data) {
      console.log('************************DATA:', data);
      setValue('order', data.order);
      setValue('attempt', data.attempt);
      setValue('message', data.message);
      setValue('value', data.value);
      // const currentCustomer = data?.customer
      setValue('customer', data.customer.id.toString());
      console.log({customer:data.customer.id.toString()});
      setValue('typeOption', data.typeOption.id.toString());
      setValue('enabled', data.enabled ? states[0].value : states[1].value);
    }
  }, [data, setValue]);

  // const password = watch('password');

  return (
    <Paper>
      {!loadingRoles ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={2}>
              <Controller
                name="order"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Order"
                      fullWidth
                      required
                      {...register('order')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="attempt"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Attempt"
                      fullWidth
                      required
                      {...register('attempt')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Status"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="message"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Message"
                      fullWidth
                      required
                      {...register('message')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="value"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Value"
                      fullWidth
                      required
                      {...register('value')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="customer"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Customer"
                      fullWidth
                      required
                      {...register('customer')}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.customer}
                      helperText={errors.customer && errors.customer.message}
                    >
                      {customers &&
                        customers.map(({ id, name }) => (
                          <MenuItem
                            key={id}
                            label={`${name}`}
                            value={`${id}`}
                          >
                            {name}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="typeOption"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Type"
                      fullWidth
                      required
                      {...register('typeOption')}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.typeOption}
                      helperText={
                        errors.typeOption && errors.typeOption.message
                      }
                    >
                      {typeOptions &&
                        typeOptions.map(({ id, code }) => (
                          <MenuItem key={id} label={`${code}`} value={`${id}`}>
                            {code}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/option"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'The option was edited successfully'}
            {open === 'error' && 'An error occurred, please try again'}
          </SnackBar>
        </form>
      ) : (
        // <form noValidate onSubmit={handleSubmit(onSubmit)}>
        //   <Grid container spacing={3} justify="flex-end">
        //     <Grid item xs={2}>
        //       <Input

        //         label="Order"
        //         value={data?.order || ''}
        //         fullWidth
        //       />
        //     </Grid>
        //     <Grid item xs={4}>
        //       <Input label="Attempt" value={data?.attempt || ''} fullWidth />
        //     </Grid>
        //     <Grid item xs={6}>
        //       <Controller
        //         name="enabled"
        //         control={control}
        //         render={({ field }) => {
        //           return (
        //             <Select
        //               value={field.value}
        //               onChange={field.onChange}
        //               label="Estado"
        //               fullWidth
        //               required
        //               enableFilter
        //             >
        //               {states?.map(({ value, name }, idx) => (
        //                 <MenuItem
        //                   key={name}
        //                   label={`${name}`}
        //                   value={`${value}`}
        //                 >
        //                   {name}
        //                 </MenuItem>
        //               ))}
        //             </Select>
        //           );
        //         }}
        //       />
        //     </Grid>

        //     <Grid item xs={6}>
        //       <Input label="Message" value={data?.message || ''} fullWidth />
        //     </Grid>
        //     <Grid item xs={6}>
        //       <Input label="Value" value={data?.value || ''} fullWidth />
        //     </Grid>

        //     <Grid item xs={6}>
        //       <Controller
        //         name="customer"
        //         control={control}
        //         render={({ field }) => {
        //           return (
        //             <Select
        //               label="Customer"
        //               fullWidth
        //               required
        //               value={field.value}
        //               onChange={field.onChange}
        //               error={!!errors.customer}
        //               helperText={errors.customer && errors.customer.message}
        //             >
        //               {customers &&
        //                 customers.map(({ id, name }) => (
        //                   <MenuItem key={id} label={`${name}`} value={`${id}`}>
        //                     {name}
        //                   </MenuItem>
        //                 ))}
        //             </Select>
        //           );
        //         }}
        //       />
        //     </Grid>

        //     <Grid item xs={6}>
        //       <Controller
        //         name="typeOption"
        //         control={control}
        //         render={({ field }) => {
        //           return (
        //             <Select
        //               label="Type"
        //               fullWidth
        //               required
        //               value={field.value}
        //               onChange={field.onChange}
        //               error={!!errors.typeOption}
        //               helperText={
        //                 errors.typeOption && errors.typeOption.message
        //               }
        //             >
        //               {typeOptions &&
        //                 typeOptions.map(({ id, code }) => (
        //                   <MenuItem key={id} label={`${code}`} value={`${id}`}>
        //                     {code}
        //                   </MenuItem>
        //                 ))}
        //             </Select>
        //           );
        //         }}
        //       />
        //     </Grid>
        //     <Grid item>
        //       <Button
        //         fullWidth
        //         variant="text"
        //         color="grey"
        //         href="/pages/management/option"
        //       >
        //         Cancelar
        //       </Button>
        //     </Grid>
        //     <Grid item>
        //       <Button
        //         type="submit"
        //         fullWidth
        //         variant="contained"
        //         loading={loading}
        //       >
        //         Actualizar
        //       </Button>
        //     </Grid>
        //   </Grid>
        //   <SnackBar
        //     wait={2000}
        //     open={open !== undefined}
        //     onClose={onClose}
        //     severity={open}
        //   >
        //     {open === 'success' && 'Se edito el usuario correctamente'}
        //     {open === 'error' && 'Ocurrió un error, intenta nuevamente'}
        //   </SnackBar>
        // </form>
        <EditOptionsTemplateLoading />
      )}
    </Paper>
  );
};

export default EditOptionsTemplate;
