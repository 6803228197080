import { object, ref, string, TypeOf, number } from 'yup';

export const EditCustomerSchema = object({
  name: string().required(),
  domain: string().required(),
  welcome: string().required(),
  enabled: string().required(),
});

// export type CreateUserType = TypeOf<typeof CreateUserSchema>;
export type EditCustomerType = TypeOf<typeof EditCustomerSchema>;

// username: string()
// .required()
// .max(100)
// .email()
// .matches(
//   new RegExp(`.+(@${customer.replace('.', '\\.')})`, 'g'),
//   `Dominio invalido, debe terminar en @${customer}`,
// ),
// password: string().required().max(15),
// passwordConfirmation: string()
// .required()
// .oneOf([ref('password'), null], 'Las contraseñas no coinciden'),
// enabled: string().required(),
// roles: string().required(),
// error: string(),
