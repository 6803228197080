import { object, ref, string, TypeOf } from 'yup';

export const CreateUserSchema = (customer: string) =>
  object({
    username: string()
      .required()
      .max(100)
      .email()
      .matches(
        new RegExp(`.+(@${customer.replace('.', '\\.')})`, 'g'),
        `Invalid domain, must end in @${customer}`,
      ),
    password: string().required().max(15),
    passwordConfirmation: string()
      .required()
      .oneOf([ref('password'), null], 'Passwords do not match'),
    enabled: string().required(),
    roles: string().required(),
    error: string(),
  });

// export type CreateUserType = TypeOf<typeof CreateUserSchema>;
export type CreateUserType = TypeOf<ReturnType<typeof CreateUserSchema>>;
