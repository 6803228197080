import { Header } from 'components/molecules';
import { ListAwsTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administration' }];

const ListAwsPage = () => {
  return (
    <>
      <Header
        title="Aws"
        subtitle="Create, edit, list and delete aws connect credentials"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <ListAwsTemplate />
    </>
  );
};

export default withAuth(ListAwsPage);
