import { object, ref, string, TypeOf } from 'yup';

export const EditUserSchema = object({
  password: string().max(15),
  passwordConfirmation: string().oneOf(
    [ref('password'), null],
    'Passwords do not match',
  ),
  enabled: string().required(),
  roles: string().required(),
  error: string(),
});

export type EditUserType = TypeOf<typeof EditUserSchema>;
