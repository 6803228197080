import {
  defaultRowType,
  paginationModelType,
  sortModelType,
} from 'components/organisms';
import { useCallback, useEffect, useState } from 'react';
import { PaginationType } from 'types';

export type filterModelType<T extends defaultRowType> = {
  field: keyof T | string;
  value: any;
  type:
    | 'contains'
    | 'equals'
    | 'between_time'
    | 'between_timestamp'
    | 'between_date'
    | 'in'
    | 'order';
  table?: string;
};

export type tableModelType<T extends defaultRowType> = {
  sortModel?: sortModelType<T>;
  paginationModel: paginationModelType;
  filterModel?: filterModelType<T>[];
};

export type useTableParams<T extends defaultRowType> = {
  onChange: (tableModel: tableModelType<T>) => Promise<void>;
  default: tableModelType<T>;
} & tableModelType<T>;

const useTable = <T extends defaultRowType>(
  defaultModels: tableModelType<T>,
  onChange: (tableModel: tableModelType<T>) => Promise<void>,
  onGetData?: (
    tableModel: Omit<tableModelType<T>, 'paginationModel'>,
  ) => Promise<PaginationType<T>>,
) => {
  const {
    sortModel: sortModelParam,
    paginationModel: paginationModelParam,
    filterModel,
  } = defaultModels;

  const [paginationModel, setPaginationModel] =
    useState<paginationModelType>(paginationModelParam);
  const [sortModel, setSortModel] = useState<sortModelType<T> | undefined>(
    sortModelParam,
  );

  const onSortModel = useCallback(
    async (sortModel?: sortModelType<T>) => {
      const newSortModel = sortModel;
      setSortModel(newSortModel);
      await onChange({
        sortModel: newSortModel,
        paginationModel,
        filterModel,
      });
    },
    [onChange, paginationModel, filterModel],
  );

  const onExportModel = useCallback(async () => {
    if (onGetData) {
      const data = await onGetData({
        sortModel,
        filterModel,
      });
      return data.result;
    }
  }, [onGetData, sortModel, filterModel]) as () => Promise<T[]>;

  const handleRowsPerPage = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      const newRowsPerPage = +target.value;
      setPaginationModel((beforePagination) => {
        const newPaginationModel = {
          ...beforePagination,
        };

        if (
          newRowsPerPage > 0 &&
          newRowsPerPage <= beforePagination.rowsCount
        ) {
          newPaginationModel.rowsPerPage = newRowsPerPage;
          newPaginationModel.page = 0;
        } else if (target.value === '') {
          newPaginationModel.rowsPerPage = '' as unknown as number;
        }

        return newPaginationModel;
      });
    },
    [],
  );

  const blurRowsPerPage = useCallback(
    ({ target }: React.FocusEvent<HTMLInputElement>) => {
      setPaginationModel((beforePagination) => {
        const newPaginationModel = {
          ...beforePagination,
          page: 0,
        };

        if ((beforePagination.rowsPerPage as unknown as string) === '') {
          newPaginationModel.rowsPerPage = paginationModelParam.rowsPerPage;
        }

        onChange({
          sortModel: sortModel,
          paginationModel: newPaginationModel,
          filterModel: filterModel,
        });
        return newPaginationModel;
      });
    },
    [paginationModelParam.rowsPerPage, onChange, sortModel, filterModel],
  );

  const handlePage = useCallback(
    (page: paginationModelType['page']) => async () => {
      setPaginationModel((beforePagination) => {
        const newPaginationModel = { ...beforePagination, page };
        onChange({
          sortModel: sortModel,
          paginationModel: newPaginationModel,
          filterModel: filterModel,
        });
        return newPaginationModel;
      });
    },
    [onChange, sortModel, filterModel],
  );

  useEffect(() => {
    setPaginationModel((beforePagination) => {
      const newPaginationModel = {
        ...beforePagination,
        rowsPerPage: paginationModelParam.rowsPerPage,
      };
      return newPaginationModel;
    });
  }, [paginationModelParam.rowsPerPage]);

  useEffect(() => {
    setPaginationModel((beforePagination) => {
      const newPaginationModel = {
        ...beforePagination,
        page: paginationModelParam.page,
      };
      return newPaginationModel;
    });
  }, [paginationModelParam.page]);

  useEffect(() => {
    setPaginationModel((beforePagination) => {
      const newPaginationModel = {
        ...beforePagination,
        rowsCount: paginationModelParam.rowsCount,
      };
      return newPaginationModel;
    });
  }, [paginationModelParam.rowsCount]);

  useEffect(() => {
    onChange({
      sortModel,
      paginationModel,
      filterModel,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel]);

  return {
    paginationModel,
    sortModel,
    onSortModel,
    onExportModel: onGetData ? onExportModel : undefined,
    handlePage,
    rowsPerPageProps: {
      onChange: handleRowsPerPage,
      onBlur: blurRowsPerPage,
    },
  };
};

export default useTable;
