import React from 'react';
import Root from './Skeleton.styles';

export type SkeletonProps = {
  height?: string;
  width?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Skeleton = ({
  height = '36px',
  width = '100%',
  ...props
}: SkeletonProps) => {
  return <Root $height={height} $width={width} {...props} />;
};

export default Skeleton;
