import { BreadCrumbs, BreadCrumbsProps, Skeleton } from 'components/atoms';
import { Root, Subtitle, Title } from './Header.styles';

export type HeaderProps = {
  loading?: boolean;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  breadCrumbsProps?: BreadCrumbsProps;
} & React.HTMLAttributes<HTMLDivElement>;

const Header = ({
  title,
  loading,
  subtitle,
  breadCrumbsProps,
  ...props
}: HeaderProps) => {
  return (
    <Root {...props}>
      {breadCrumbsProps?.breadcrumbs && (
        <BreadCrumbs loading={loading} {...breadCrumbsProps} />
      )}

      {title && !loading && (
        <Title variant="h1" wrap bold>
          {title}
        </Title>
      )}
      {subtitle && !loading && (
        <Subtitle variant="h2" bold={false}>
          {subtitle}
        </Subtitle>
      )}

      {loading && (
        <Title variant="h1" wrap bold>
          <Skeleton width="300px" height="26px" />
        </Title>
      )}

      {subtitle && loading && (
        <Subtitle variant="h2" bold={false}>
          <Skeleton height="20px" />
        </Subtitle>
      )}
    </Root>
  );
};

export default Header;
