export const errors = {
  INVALID: 'The value is invalid',
  REQUIRED: 'The value is required',
  EMAIL: 'The email is not valid',
  MAX: 'Exceeded the maximum characters',
  INTEGER: 'Not a numeric value',
};

export const mapErrors: Record<string, { constrain: string; message: string }> =
  {
    isDefined: { constrain: 'required', message: errors.REQUIRED },
    isNotEmpty: { constrain: 'required', message: errors.REQUIRED },
    isString: { constrain: 'string', message: errors.REQUIRED },
    isCustom: { constrain: 'custom', message: '' },
  };
