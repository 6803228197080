import { useCallback, useMemo, useRef } from 'react';
import {
  HiDownload,
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
  HiOutlineChevronLeft,
  HiOutlineChevronRight,
} from 'react-icons/hi';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Grid, IconButton, Typography } from 'components/atoms';
import {
  BasicTable,
  defaultRowType,
  TableCell,
  TableRow,
} from 'components/molecules';

import { dataToHTMLTable } from 'utils';
import { SortTable } from './components';
import {
  Grow,
  InputPagination,
  Svg,
  TableContainer,
  TableFooter,
  TableHead,
  Title,
  Toolbar,
} from './Table.styles';
import { sortModelType, TableProps } from './Table.types';

const uri = 'data:application/vnd.ms-excel;base64,';
const template =
  '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
  'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
  'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
  'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
  '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
  'xml><![endif]--></head><body>{table}</body></html>';

const CustomTable = <T extends defaultRowType>({
  columns,
  title,
  rows,
  onExportModel,
  onSortModel,
  handlePage,
  paginationModel,
  sortModel,
  exportOptions,
  rowsPerPageProps = {},
  filterComponent,
  numberOfVisibleColumns,
  loading,
  error,
}: TableProps<T>) => {
  const ref = useRef<HTMLTableElement>(null);

  const exportTable = useCallback(async () => {
    try {
      if (onExportModel) {
        const data = await onExportModel();

        const headers = columns.map(({ headerName }) => headerName);
        const rowsExcel = data.map((row) =>
          columns.map(({ field, render }) =>
            render ? render(row) : row[field],
          ),
        );
        rowsExcel.unshift(headers);
        const table = dataToHTMLTable(rowsExcel);

        const newExportOptions = {
          table,
          worksheet: exportOptions?.worksheet || 'Reporte',
        };

        const content = template.replace(
          /{(\w+)}/g,
          (_, p) => newExportOptions[p as keyof typeof newExportOptions],
        );

        const base64 = window.btoa(unescape(encodeURIComponent(content)));

        const element = window.document.createElement('a');
        element.href = uri + base64;
        element.download = `${exportOptions?.filename || title}.xls`;
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    } catch (error) {
      console.log(error);
    }
  }, [exportOptions, title, columns, onExportModel]);

  const numberOfRecords = useMemo(
    () => paginationModel.page * paginationModel.rowsPerPage + 1,
    [paginationModel.page, paginationModel.rowsPerPage],
  );

  const totalPages = useMemo(
    () =>
      Math.ceil(
        paginationModel.rowsCount /
          (paginationModel.rowsPerPage ? paginationModel.rowsPerPage : 1),
      ),
    [paginationModel.rowsCount, paginationModel.rowsPerPage],
  );

  const numberOfRecordsDisplayed = useMemo(
    () => numberOfRecords - 1 + rows.length,
    [rows.length, numberOfRecords],
  );

  const handleSort = useCallback(
    (field: sortModelType<T>['field'], table?: sortModelType<T>['table']) =>
      (order?: sortModelType<T>['order']) => {
        const newSortModel = order
          ? {
              field,
              table,
              order,
            }
          : undefined;
        if (onSortModel) onSortModel(newSortModel);
      },
    [onSortModel],
  );

  return (
    <TableContainer $height={paginationModel.rowsPerPage}>
      <Toolbar>
        <PerfectScrollbar>
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="space-between"
          >
            {title && (
              <Grid item>
                {typeof title === 'string' ? (
                  <Title variant="h2" wrap={false}>
                    {title}
                  </Title>
                ) : (
                  title
                )}
              </Grid>
            )}
            <Grid item xs>
              {filterComponent}
            </Grid>
          </Grid>
        </PerfectScrollbar>
      </Toolbar>
      <TableHead as="div">
        <TableRow as="div">
          {columns.map(
            (
              { headerName, field, table, flex, disabledSort, description },
              idx,
            ) => (
              <TableCell
                as="div"
                key={`head.${field}.${headerName}`}
                $flex={flex}
              >
                {headerName}
                {rows.length > 0 && !disabledSort && (
                  <SortTable
                    onSortModel={handleSort(field, table)}
                    sort={
                      sortModel?.field === field ? sortModel.order : undefined
                    }
                  />
                )}
                {/* {description ? (
                  <Tooltip content={<>{description}</>}>
                    <div>{headerName}</div>
                  </Tooltip>
                ) : (
                  headerName
                )} */}
                <Grow />
                {columns.length - 1 > idx && (
                  <Svg
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="SeparatorIcon"
                  >
                    <path d="M11 19V5h2v14z"></path>
                  </Svg>
                )}
              </TableCell>
            ),
          )}
        </TableRow>
      </TableHead>
      <PerfectScrollbar>
        <BasicTable<T>
          error={error}
          numberOfVisibleColumns={numberOfVisibleColumns}
          ref={ref}
          loading={loading}
          columns={columns}
          rows={rows}
          rowsPerPage={paginationModel.rowsPerPage}
        />
      </PerfectScrollbar>
      {rows.length > 0 && (
        <TableFooter>
          <PerfectScrollbar>
            <Grid container alignItems="center" spacing={4} wrap="nowrap">
              {exportOptions && (
                <Grid item>
                  <IconButton
                    info={{ message: 'Descargar reporte', position: 'right' }}
                    color="grey"
                    onClick={onExportModel ? exportTable : undefined}
                    disabled={rows.length === 0 || loading}
                  >
                    <HiDownload size="19" />
                  </IconButton>
                </Grid>
              )}
              <Grid item style={{ flexGrow: 1 }} />
              <Grid item>
                <Typography wrap={false}>Rows per page:</Typography>
              </Grid>
              <Grid item>
                <InputPagination
                  {...rowsPerPageProps}
                  fullWidth
                  type="number"
                  value={paginationModel.rowsPerPage}
                  disabled={loading}
                  inputProps={{
                    max: paginationModel.rowsCount,
                    min: 1,
                  }}
                />
              </Grid>
              <Grid item>
                <Typography
                  wrap={false}
                >{`${numberOfRecords} - ${numberOfRecordsDisplayed} of ${paginationModel.rowsCount} results`}</Typography>
              </Grid>
              <Grid item>
                <IconButton
                  info="Primera página"
                  color="grey"
                  onClick={handlePage(0)}
                  disabled={loading || paginationModel.page === 0}
                >
                  <HiOutlineChevronDoubleLeft size="19" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  info="Página anterior"
                  color="grey"
                  onClick={handlePage(paginationModel.page - 1)}
                  disabled={loading || paginationModel.page === 0}
                >
                  <HiOutlineChevronLeft size="19" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  info="Siguiente página"
                  color="grey"
                  onClick={handlePage(paginationModel.page + 1)}
                  disabled={loading || paginationModel.page + 1 === totalPages}
                >
                  <HiOutlineChevronRight size="19" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  info="Última página"
                  color="grey"
                  onClick={handlePage(totalPages - 1)}
                  disabled={loading || paginationModel.page + 1 === totalPages}
                >
                  <HiOutlineChevronDoubleRight size="19" />
                </IconButton>
              </Grid>
            </Grid>
          </PerfectScrollbar>
        </TableFooter>
      )}
    </TableContainer>
  );
};

export default CustomTable;
