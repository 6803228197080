export const CUSTOMER =
  process.env.REACT_APP_API + '/v1/customer?domainOrSuffix=:customer';

export const LOGIN = '/v1/auth/login';
export const LOGIN_GOOGLE = '/v1/auth/google';
export const LOGIN_AZURE = '/v1/auth/azure';
export const LOGOUT = '/v1/auth/logout';
export const REQUEST_PERMISSIONS = '/v1/auth/request-permissions';
export const CHANGE_PASSWORD = '/v1/auth/change-password';

export const GET_PROCESS = '/v1/processes/:id';

export const SEARCH_TRANSACTIONS = '/v1/transactions';
export const GET_STATUS_BY_PROCESS = '/v1/transactions/:id/state';
export const RE_PROCESS_TRANSACTION = '/v1/transactions/:id/reprocess';
export const SEARCH_TRANSACTIONS_PER_CHECK = '/v1/transactions/verification';

export const GET_DOCUMENTS_BY_TRANSACTIONS_PER_VERIFICATION =
  '/v1/documents/verification-transaction';
export const FIX_DOCUMENT_VERIFICATION = '/v1/documents/fix/:id';
export const REJECT_DOCUMENT_VERIFICATION = '/v1/documents/reject/:id';

export const CREATE_USER = 'v1/user';
export const GET_USERS = 'v1/user';
export const DELETE_USER = 'v1/user/:id';
export const UPDATE_USER_STATE = 'v1/user/toggle-state/:id';
export const UPDATE_USER = 'v1/user/:id';
export const GET_USER = 'v1/user/:id';

export const GET_ROLES = 'v1/role';
export const CREATE_ROLE = 'v1/role';
export const DELETE_ROLE = 'v1/role/:id';
export const UPDATE_ROLE_STATE = 'v1/role/toggle-state/:id';
export const UPDATE_ROLE = 'v1/role/:id';
export const GET_ROLE = 'v1/role/:id';

export const GET_ROLE_MENU = 'v1/menu';

export const GET_OPTIONS = 'v1/options';
export const GET_OPTION = 'v1/options/:id';
export const UPDATE_OPTIONS = 'v1/options/:id';
export const CREATE_OPTIONS = 'v1/options';

export const GET_CUSTOMERS = 'v1/customer';
export const GET_CUSTOMER = 'v1/customer/:id';
export const UPDATE_CUSTOMER = 'v1/customer/:id';
export const CREATE_CUSTOMER = 'v1/customer';

export const GET_AWS_CREDENTIALS = 'v1/aws';
export const GET_AWS_CREDENTIAL = 'v1/aws/:id';
export const UPDATE_AWS_CREDENTIALS = 'v1/aws/:id';
export const CREATE_AWS_CREDENTIALS = 'v1/aws';

export const GET_TYPEOPTIONS = 'v1/typeOption';
