import { Container as AtomContainer, Grid, IconButton } from 'components/atoms';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components';
import { darken, invertColor } from 'utils';

export const Root = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) =>
    darken(invertColor(theme.palette.background, true), 0.2)};
  height: 100vh;
`;

export const Container = styled(AtomContainer)`
  color: inherit;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 67px);
  min-height: calc(100vh - 67px);

  padding: ${({ theme }) => theme.shape.spacing(2)};

  @media (min-width: ${({ theme }) => theme.breakpoints.values.sm}) {
    padding: ${({ theme }) => theme.shape.spacing(3)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}) {
    padding: ${({ theme }) => theme.shape.spacing(4)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.values.lg}) {
    padding: ${({ theme }) => theme.shape.spacing(6)};
  }
`;

export const CollapseIcon = styled(IconButton)<{ $open: boolean }>`
  position: absolute;
  right: -17px;
  bottom: calc(50% - 7px);
  cursor: pointer;

  border-radius: 50%;
  transform: ${({ $open }) => (!$open ? 'rotate(270deg)' : 'rotate(90deg)')};
  z-index: 1;
`;

export const GridMenu = styled(Grid)`
  ${({ theme }) =>
    theme.isDarkMode
      ? `border-right: thin solid ${theme.palette.divider}`
      : `border-top: thin solid ${theme.palette.divider}`};
  box-sizing: border-box;
  position: relative;
  max-height: calc(100vh - 67px);
  background-color: ${({ theme }) =>
    theme.isDarkMode ? 'transparent' : theme.common.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}) {
    display: none;
  }
`;

export const GridContent = styled(Grid)`
  max-height: calc(100vh - 67px);
  box-sizing: border-box;
`;

export const GridContainer = styled(Grid)`
  margin-top: 67px;
`;

export const CustomPerfectScrollbar = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Footer = styled.div<{ $open: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: ${({ $open }) => (!$open ? '25px' : '250px')};
  opacity: ${({ $open }) => (!$open ? '0' : '1')};
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  & > img {
    height: 45px;
  }
`;
