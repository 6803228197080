import axios, { AxiosRequestConfig } from 'axios';
import { GET_ROLES, GET_CUSTOMERS, GET_AWS_CREDENTIAL } from 'const';
import { Option, Transaction, Aws } from 'types';

export const fetchRoles = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_ROLES, config);
};

export const fetchCustomers = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_CUSTOMERS, config);
};



export const fetchAws = ({ id }: any, config?: AxiosRequestConfig<Option>) => {
  return axios.get<Aws>(`${GET_AWS_CREDENTIAL.replace(':id', id)}`, config);
};
