import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_AWS_CREDENTIALS } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { PaginationType, Aws } from 'types';
import { ListAwsTemplateFilterType } from './ListAwsTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 10,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Aws> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListAwsTemplateFilter: ListAwsTemplateFilterType = {
  aws: undefined,
};

export const getColumns: (
  onDelete: (id: number) => void,
  onActive: (id: number) => void,
) => columnType<Aws>[] = (onDelete, onActive) => [
  // {
  //   field: 'name',
  //   table: 'name',
  //   headerName: 'Name',
  //   flex: '4',
  //   exportField: true,
  //   render: ({ name }) => name,
  // },
  {
    field: 'region',
    table: 'region',
    headerName: 'Region',
    flex: '4',
    exportField: true,
    render: ({ region }) => region,
  },
  // {
  //   field: 'accessKeyId',
  //   table: 'accessKeyId',
  //   headerName: 'Access Key Id',
  //   flex: '4',
  //   exportField: true,
  //   render: ({ accessKeyId }) => accessKeyId,
  // }, {
  //   field: 'secretAccessKey',
  //   table: 'secretAccessKey',
  //   headerName: 'Secret Access Key',
  //   flex: '4',
  //   exportField: true,
  //   render: ({ secretAccessKey }) => secretAccessKey,
  // },

  {
    field: 'instanceId',
    table: 'instanceId',
    headerName: 'Instance Id',
    flex: '4',
    exportField: true,
    render: ({ instanceId }) => instanceId,
  },

  {
    field: 'contactFlowId',
    table: 'contactFlowId',
    headerName: 'Contact Flow Id',
    flex: '4',
    exportField: true,
    render: ({ contactFlowId }) => contactFlowId,
  },

  {
    field: 'enabled',
    headerName: 'Status',
    flex: '1',
    exportField: true,
    render: ({ enabled }) => (enabled ? 'Enable' : 'Disable'),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: ({ id, enabled }) => (
      <>
        <IconButton
          info={enabled ? 'Disable aws' : 'Enable aws'}
          color={enabled ? 'success' : 'grey'}
          onClick={() => onActive(id)}
        >
          <AiOutlineCheck />
        </IconButton>
        <IconButton
          info="Edit aws"
          positionEdge="start"
          color="grey"
          href={`/pages/management/aws/${id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        <IconButton
          info="Delete aws"
          positionEdge="start"
          color="error"
          onClick={() => onDelete(id)}
        >
          <AiOutlineDelete />
        </IconButton>
      </>
    ),
  },
];

export const fetchProcess = (
  { filterModel, sortModel, paginationModel }: tableModelType<Aws>,
  config?: AxiosRequestConfig<Aws>,
) => {
  return axios.get<PaginationType<Aws>>(
    `${GET_AWS_CREDENTIALS}?${
      filterModel && filterModel.length > 0
        ? `filter=${JSON.stringify(filterModel)}&`
        : ''
    }${
      paginationModel
        ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
        : ''
    }`,
    config,
  );
};
