import { object, ref, string, TypeOf, number } from 'yup';

export const CreateAwsSchema = (aws: string) =>
  object({
    name: string().required(),
    region: string().required(),
    accessKeyId: string().required(),
    secretAccessKey: string().required(),
    instanceId: string().required(),
    contactFlowId: string().required(),
    enabled: string().required(),
  });

// export type CreateUserType = TypeOf<typeof CreateUserSchema>;
export type CreateAwsType = TypeOf<ReturnType<typeof CreateAwsSchema>>;

// username: string()
// .required()
// .max(100)
// .email()
// .matches(
//   new RegExp(`.+(@${customer.replace('.', '\\.')})`, 'g'),
//   `Dominio invalido, debe terminar en @${customer}`,
// ),
// password: string().required().max(15),
// passwordConfirmation: string()
// .required()
// .oneOf([ref('password'), null], 'Las contraseñas no coinciden'),
// enabled: string().required(),
// roles: string().required(),
// error: string(),
