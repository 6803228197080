import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { GET_USERS, GET_OPTIONS, GET_CUSTOMERS } from 'const';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineCheck, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { PaginationType, User, Option, CustomerOption } from 'types';
import { ListCustomerTemplateFilterType } from './ListCustomerTemplate.schema';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 10,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<CustomerOption> = {
  field: 'id',
  order: 'ASC',
};

export const defaultListCustomerTemplateFilter: ListCustomerTemplateFilterType = {
  customer: undefined,
};

export const getColumns: (
  onDelete: (id: number) => void,
  onActive: (id: number) => void,
) => columnType<CustomerOption>[] = (onDelete, onActive) => [
  {
    field: 'name',
    headerName: 'Name',
    flex: '5',
    exportField: true,
    render: ({ name }) => name,
  
  },
  {
    field: 'domain',
    table: 'domain',
    headerName: 'Domain',
    flex: '4',
    exportField: true,
    render: ({ domain }) => domain,
  },

  {
    field: 'welcome',
    table: 'welcome',
    headerName: 'Welcome Message',
    flex: '4',
    exportField: true,
    render: ({ welcome }) => welcome,
  },
  
  {
    field: 'enabled',
    headerName: 'Status',
    flex: '1',
    exportField: true,
    render: ({ enabled }) => (enabled ? 'Enable' : 'Disable'),
  },
  {
    field: 'actions',
    headerName: 'Actions',
    // align: "flex-end",
    flex: '2',
    disabledSort: true,
    render: ({ id, enabled }) => (
      <>
        <IconButton
          info={enabled ? 'Disable customer' : 'Enable customer'}
          color={enabled ? 'success' : 'grey'}
          onClick={() => onActive(id)}
        >
          <AiOutlineCheck />
        </IconButton>
        <IconButton
          info="Edit customer"
          positionEdge="start"
          color="grey"
          href={`/pages/management/customer/${id}/edit`}
        >
          <AiOutlineEdit />
        </IconButton>
        {/* <IconButton
          info="Delete customer"
          positionEdge="start"
          color="error"
          onClick={() => onDelete(id)}
        >
          <AiOutlineDelete />
        </IconButton> */}
      </>
    ),
  },
];

export const fetchProcess = (
  { filterModel, sortModel, paginationModel }: tableModelType<CustomerOption>,
  config?: AxiosRequestConfig<CustomerOption>,
) => {
  return axios.get<PaginationType<CustomerOption>>(
    `${GET_CUSTOMERS}?${
      filterModel && filterModel.length > 0
        ? `filter=${JSON.stringify(filterModel)}&`
        : ''
    }${
      paginationModel
        ? `limit=${paginationModel.rowsPerPage}&page=${paginationModel.page}`
        : ''
    }`,
    config,
  );
};
