import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  startOfMonth,
  startOfToday,
  startOfWeek,
  startOfYear,
  startOfYesterday
} from 'date-fns';
import { es } from 'date-fns/locale';
import { Range } from 'react-date-range';
import { isSelected } from 'utils';

export const DATE_FORMAT_WITH_OUT_TIME = 'PP';
export const TIME_FORMAT_WITH_OUT_DATE = 'hh:mm aaa';
export const DATE_FORMAT = 'PP, hh:mm aaa';
export const DATE_FORMAT_BACK_WITH_OUT_TIME = 'yyyy/MM/dd';
export const DATE_FORMAT_BACK_WITH_OUT_DATE = 'HH:mm:ss';
export const COMMON_OPTIONS = { locale: es };

const newDate = new Date();

type createStaticRangesParams = {
  label: string;
  range: () => Range;
};

const createStaticRanges = (ranges: createStaticRangesParams[]) => {
  return ranges.map((range) => {
    return { ...range, isSelected };
  });
};

export const defaultStaticRanges = createStaticRanges([
  {
    label: 'Hoy',
    range: function range() {
      return {
        startDate: startOfToday(),
        endDate: endOfToday(),
      };
    },
  },
  {
    label: 'Ayer',
    range: function range() {
      return {
        startDate: startOfYesterday(),
        endDate: endOfYesterday(),
      };
    },
  },
  {
    label: 'Estas semana',
    range: function range() {
      return {
        startDate: startOfWeek(newDate),
        endDate: endOfWeek(newDate),
      };
    },
  },
  {
    label: 'La ultima semana',
    range: function range() {
      return {
        startDate: startOfWeek(addDays(newDate, -7)),
        endDate: endOfWeek(newDate),
      };
    },
  },
  {
    label: 'Este mes',
    range: function range() {
      return {
        startDate: startOfMonth(newDate),
        endDate: endOfMonth(newDate),
      };
    },
  },
  {
    label: 'El ultimo mes',
    range: function range() {
      return {
        startDate: startOfMonth(addMonths(newDate, -1)),
        endDate: endOfMonth(addMonths(newDate, -1)),
      };
    },
  },
  {
    label: 'Este año',
    range: function range() {
      return {
        startDate: startOfYear(newDate),
        endDate: endOfYear(newDate),
      };
    },
  },
  {
    label: 'El ultimo año',
    range: function range() {
      return {
        startDate: startOfYear(addYears(newDate, -1)),
        endDate: endOfYear(addYears(newDate, -1)),
      };
    },
  },
]);
