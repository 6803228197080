import { HiChevronDown } from 'react-icons/hi';
import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';
import { darken, invertColor } from 'utils';

export const Link = styled(RouteLink)<{
  $active?: boolean;
  $deep: number;
}>`
  padding: 12px 16px 12px calc(18px + ${({ $deep }) => 10 * $deep}px);
  display: block;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  background-color: ${({ $active: active, theme }) =>
    active ? theme.palette.primary : 'transparent'};
  color: ${({ theme, $active: active }) =>
    active
      ? theme.isDarkMode
        ? invertColor(theme.palette.primary, true)
        : invertColor(theme.palette.primary, true)
      : theme.isDarkMode
      ? darken(theme.common.white, 0.2)
      : darken(theme.common.white, 0.7)};

  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ExpandIcon = styled(HiChevronDown)``;

export const FatherLink = styled.div<{
  $open?: boolean;
  $deep: number;
}>`
  padding: 12px 16px 12px calc(18px + ${({ $deep }) => 10 * $deep}px);
  display: flex;
  justify-content: space-between;
  transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${({ theme }) =>
    theme.isDarkMode
      ? darken(theme.common.white, 0.2)
      : darken(theme.common.white, 0.7)};

  text-decoration: none;
  font-weight: 400;
  font-size: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > ${ExpandIcon} {
    transition: all 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transform: ${({ $open: active }) =>
      active ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;
