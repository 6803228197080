import axios, { AxiosRequestConfig } from 'axios';
import { IconButton } from 'components/atoms';
import { columnType } from 'components/molecules';
import { paginationModelType, sortModelType } from 'components/organisms';
import { COMMON_OPTIONS, DATE_FORMAT, SEARCH_TRANSACTIONS } from 'const';
import { format, startOfMonth } from 'date-fns';
import { tableModelType } from 'hooks/useTable';
import { AiOutlineReload } from 'react-icons/ai';
import { customerType, Transaction } from 'types';
import {
  createFilterStructure,
  createPayload,
  selectMultipleValueAsArray
} from 'utils';
import { ProcessTemplateFilterType } from './ProcessTemplate.schema';
import { responseType } from './ProcessTemplate.types';

export const defaultPagination: paginationModelType = {
  page: 0,
  rowsPerPage: 10,
  rowsCount: 0,
};

export const defaultSortModel: sortModelType<Transaction> = {
  field: 'id',
  order: 'ASC',
};

export const getDefaultProcessTemplateFilter: (
  status: string,
) => ProcessTemplateFilterType = (status) => {
  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date();
  endDate.setHours(23, 59, 59, 999);

  return {
    dateRange: {
      startDate: startOfMonth(startDate),
      endDate,
    },
    package_: undefined,
    status: '',
  };
};

const extraColumns: Record<customerType, columnType<Transaction>[]> = {
  marval: [
    {
      field: `name`,
      headerName: 'Ultimo estado',
      align: 'flex-start',
      flex: '1',
      table: 'lastState',
      exportField: true,
      render: ({ lastState }) => lastState?.name,
    },
  ],
  prodesa: [],
  buenvivir: [],
  mayasoft: [],
  gmail: [],
  cusezar:[],
  auxis:[],
  alcabama:[]
};

export const getColumns: (
  customer: customerType,
  onReprocessed: (id: number) => void,
  canBeReprocessed?: boolean,
) => columnType<Transaction>[] = (
  customer,
  onReprocessed,
  canBeReprocessed,
) => {
  const additional: columnType<Transaction>[] = [];

  if (canBeReprocessed)
    additional.push({
      field: 'actions',
      headerName: 'Acciones',
      // align: 'flex-end',
      flex: '1',
      disabledSort: true,
      render: ({ id, reprocessed }) => (
        <IconButton
          disabled={reprocessed}
          info="Re-procesar paquete"
          color="info"
          onClick={() => onReprocessed(id)}
        >
          <AiOutlineReload />
        </IconButton>
      ),
    });

  const columns: columnType<Transaction>[] = [
    {
      field: 'id_transaction',
      headerName: 'Paquete',
      flex: '6',
      exportField: true,
      render: ({ idTransaction }) => idTransaction,
    },
    {
      field: 'transaction_date',
      headerName: 'Fecha',
      align: 'center',
      flex: '2',
      exportField: true,
      render: ({ transactionDate }) => format(new Date(transactionDate), DATE_FORMAT, COMMON_OPTIONS),
    },
    {
      field: 'id',
      headerName: 'Estado',
      table: 'state',
      align: 'flex-start',
      flex: '1',
      exportField: true,
      render: ({ status }) => status.name,
    },
    ...extraColumns[customer],
    ...additional,
  ];

  return columns;
};

export const fetch = <T extends object>(
  { filterModel, ...pagination }: tableModelType<Transaction<T>>,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  const copyFilterModel = filterModel ? [...filterModel] : [];
  const filterStatusIndex = copyFilterModel.findIndex(
    ({ field, table }) => field === 'id' && table === 'state',
  );

  if (filterStatusIndex !== -1) {
    const status = createFilterStructure(
      'id',
      'in',
      selectMultipleValueAsArray(
        copyFilterModel[filterStatusIndex].value as string,
      ),
      'state',
    );
    copyFilterModel.splice(filterStatusIndex, 1);
    if (status) copyFilterModel.push(status);
  }

  return axios.get<responseType<T>>(
    `${SEARCH_TRANSACTIONS}${createPayload({
      ...pagination,
      filterModel: copyFilterModel,
    })}`,
    config,
  );
};
