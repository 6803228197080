import styled from 'styled-components';
import { invertColor, darken } from 'utils';
import { Container as AtomContainer, Grid } from 'components/atoms';

export const Root = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) =>
    darken(invertColor(theme.palette.background, true), 0.2)};
  height: 100vh;
`;

export const Container = styled(AtomContainer)`
  // background: ${({ theme }) => darken(theme.palette.primary, 0.1)};
  color: inherit;
  padding: ${({ theme }) => theme.shape.spacing(0)};
  border-radius: ${({ theme }) => theme.shape.borderRadius} @media
    (max-width: ${({ theme }) => theme.breakpoints.values.md}) {
    background: inherit;
  }
`;

export const GridImage = styled(Grid)`
  border-radius: ${({ theme }) => theme.shape.borderRadius};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}) {
    display: none;
  }
`;

export const GridContent = styled(Grid)`
  background: ${({ theme }) =>
    theme.isDarkMode
      ? darken(theme.palette.background, 0.1)
      : theme.common.white};
  padding: ${({ theme }) => theme.shape.spacing(5)};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 38px;
  }
`;
