import { LoginTemplate } from 'components/templates';
import { withoutAuth } from 'HOCs';
import { useAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  // const pathname = loadState<string>('redirect') || '/dashboard';
  const pathname = '/dashboard';
  const { login } = useAuth();

  return (
    <>
      <LoginTemplate
        onSuccess={(data) => {
          login(data);
          navigate(pathname);
        }}
      />
    </>
  );
};

export default withoutAuth(LoginPage);
