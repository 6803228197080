import { isSameDay } from 'date-fns';
import { Range } from 'react-date-range';
export const isSelected = (range: Range) => {
  return (
    (range.startDate &&
      range.endDate &&
      isSameDay(range.startDate, range.startDate) &&
      isSameDay(range.endDate, range.endDate)) ||
    false
  );
};
