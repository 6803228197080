import { Alert, Skeleton } from 'components/atoms';
import { useForkRef } from 'hooks';
import { forwardRef, useRef } from 'react';
import { Table, TableBody, TableCell, TableRow } from './BasicTable.styles';
import { BasicTableProps, defaultRowType } from './BasicTable.types';

const BasicTable = <T extends defaultRowType = defaultRowType>(
  {
    columns,
    rows,
    loading,
    error,
    rowsPerPage,
    numberOfVisibleColumns = rowsPerPage,
  }: BasicTableProps<T>,
  ref: React.ForwardedRef<HTMLTableElement>,
) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const handleRef = useForkRef(ref, tableRef);

  return (
    <Table ref={handleRef} $height={numberOfVisibleColumns}>
      <TableBody>
        {error ? (
          <TableRow>
            <TableCell $disabledBorder>
              <Alert severity="error">
                An error occurred, please try again
              </Alert>
            </TableCell>
          </TableRow>
        ) : (
          <>
            {loading ? (
              Array(rowsPerPage)
                .fill('')
                .map((_, idx) => (
                  <TableRow key={`loading.body.${idx}`}>
                    {columns.map(({ field, flex, align, headerName }) => (
                      <TableCell
                        key={`loading.cell.${idx}.${field}.${headerName}`}
                        $flex={flex}
                        $align={align}
                      >
                        <Skeleton />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
            ) : rows.length > 0 ? (
              rows.map((row, idx) => (
                <TableRow key={`body.${row.id}`}>
                  {columns.map(({ field, flex, align, headerName, render }) => (
                    <TableCell
                      key={`cell.${row.id}.${field}.${headerName}`}
                      $flex={flex}
                      $align={align}
                      $disabledBorder={idx + 1 === rows.length}
                    >
                      {render ? render(row) : row[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell $disabledBorder>
                  <Alert severity="info">No records found.</Alert>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default forwardRef<HTMLTableElement, BasicTableProps<defaultRowType>>(
  BasicTable,
) as <T extends defaultRowType = defaultRowType>(
  props: BasicTableProps<T> & { ref?: React.ForwardedRef<HTMLTableElement> },
) => ReturnType<typeof BasicTable>;
