import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  InputPassword,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { UPDATE_AWS_CREDENTIALS } from 'const';
import { states } from 'const/const';
import { useGetTable } from 'hooks';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerOption, TypeOption, Option, Role, Aws } from 'types';
import { cleanInvalidObj, getErrorsFromBackend } from 'utils';
import { fetchAws, fetchCustomers, fetchRoles } from './EditAwsTemplate.const';
import EditAwsTemplateLoading from './EditAwsTemplate.loading';
import { EditAwsSchema, EditAwsType } from './EditAwsTemplate.schema';

const EditAwsTemplate = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(loading);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<EditAwsType>({
    resolver: yupResolver(EditAwsSchema),
    defaultValues: {
      enabled: '',
    },
  });

  const { data: roles, loading: loadingRoles } = useGetTable<Role[]>(
    fetchRoles,
    [{}],
  );
  const { data: customers, loading: loadingCustomer } = useGetTable<
    CustomerOption[]
  >(fetchCustomers, [{}]);

  const { data } = useGetTable<Aws, { id: string }>(fetchAws, {
    id: id as string,
  });

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/aws');
  };

  const onSubmit = async (loginForm: EditAwsType) => {
    try {
      setLoading(true);
      const { ...form } = loginForm;
      // const plainRoles = roles?.split(',').filter((item) => item !== '');
      const enabled = form.enabled === 'true';

      //TO DO: array to obj
      const data = cleanInvalidObj(form);

      await axios.put<Option>(
        UPDATE_AWS_CREDENTIALS.replace(':id', id as string),
        {
          ...data,
          enabled,
        },
      );

      console.log(data);
      setLoading(false);
      setOpen('success');
    } catch (error) {
      console.log('Passsoooo un Error');
      setLoading(false);
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditAwsType>(error);

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };

  useEffect(() => {
    if (data) {
      setValue('name', data.name);
      setValue('region', data.region);
      setValue('instanceId', data.instanceId);
      setValue('contactFlowId', data.instanceId);
      setValue('secretAccessKey', data.secretAccessKey);
      setValue('accessKeyId', data.accessKeyId);
      // const currentCustomer = data?.customer
      // setValue('customer', currentCustomer);
      // setValue('typeOption', data.typeOption.code);
      setValue('enabled', data.enabled ? states[0].value : states[1].value);
    }
  }, [data, setValue]);

  // const password = watch('password');

  return (
    <Paper>
      {!loadingRoles ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={2}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Name"
                      fullWidth
                      required
                      {...register('name')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="region"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Region"
                      fullWidth
                      required
                      {...register('region')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Status"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <Controller
                name="instanceId"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Instance Id"
                      fullWidth
                      required
                      {...register('instanceId')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="contactFlowId"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      label="Contact Flow Id"
                      fullWidth
                      required
                      {...register('contactFlowId')}
                      value={field.value}
                      onChange={field.onChange}
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="secretAccessKey"
                control={control}
                render={({ field }) => {
                  return (
                    <InputPassword
                      label="New Secret Access Key"
                      fullWidth
                      required 
                      onChange={field.onChange}
                     
                      //  value={"order"}
                      //  error={!!errors.order}
                      //  helperText={errors.order ? errors.order.message : `Error`}
                      //Aqui termina Input
                    />
                  );
                }}
              />
            </Grid>
        

            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/aws"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'The aws credentials was edited successfully'}
            {open === 'error' && 'An error occurred, please try again'}
          </SnackBar>
        </form>
      ) : (
        <EditAwsTemplateLoading />
      )}
    </Paper>
  );
};

export default EditAwsTemplate;
