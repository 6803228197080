import { Divider, Grid, Typography } from 'components/atoms';
import { LoginForm, LoginFormProps, LoginProviders } from './components';
import { GridDivider, GridHeader } from './LoginTemplate.styles';

export type LoginTemplateProps = {} & LoginFormProps;

const LoginTemplate = ({ onSuccess }: LoginTemplateProps) => {
  return (
    <Grid container>
      <GridHeader item xs={12}>
        <Typography variant="h1" color="inherit" align="center">
        Robbie Portal
        </Typography>
        <Typography variant="p" color="inherit" align="center">
        Access with your account
        </Typography>
      </GridHeader>
      {/* <Grid item xs={12}>
        <LoginProviders onSuccess={onSuccess} />
      </Grid> */}
      {/* <GridDivider item xs={12}>
        <Divider>Or</Divider>
      </GridDivider> */}
      <Grid item xs={12}>
        <LoginForm onSuccess={onSuccess} />
      </Grid>
    </Grid>
  );
};

export default LoginTemplate;
