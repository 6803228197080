import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { UPDATE_ROLE } from 'const';
import { states } from 'const/const';
import { useGetTable } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Menu, Role, User } from 'types';
import { flatMenus, getErrorsFromBackend } from 'utils';
import { fetchMenus, fetchRole, fetchUsers } from './EditRoleTemplate.const';
import CreateUserTemplateLoading from './EditRoleTemplate.loading';
import { EditRoleSchema, EditRoleType } from './EditRoleTemplate.schema';

const CreateUserTemplate = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<EditRoleType>({
    resolver: yupResolver(EditRoleSchema),
    defaultValues: {
      enabled: 'true',
      menus: '',
      users: '',
    },
  });

  const { data: menusFetch, loading: loadingMenus } = useGetTable<Menu[]>(
    fetchMenus,
    [true],
  );

  const { data: users, loading: loadingUsers } = useGetTable<User[]>(
    fetchUsers,
    [true],
  );

  const { data, loading: loadingRole } = useGetTable<Role, { id: string }>(
    fetchRole,
    { id: id as string },
  );

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/role');
  };

  const onSubmit = async (loginForm: EditRoleType) => {
    try {
      setLoading(true);
      const { menus, users, enabled, ...form } = loginForm;
      const copyMenus = menus
        ?.replace(/-/g, ',')
        .split(',')
        .filter((item) => item !== '') as string[];
      const plainMenus = Array.from(new Set(copyMenus));
      const plainUsers = users?.split(',').filter((item) => item !== '');
      const isEnabled = enabled === 'true';

      await axios.put<Role>(UPDATE_ROLE.replace(':id', id as string), {
        ...form,
        enabled: isEnabled,
        menus: plainMenus,
        users: plainUsers,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      const { isGeneral, errorOptions } =
        getErrorsFromBackend<EditRoleType>(error);

      if (isGeneral) {
        setError(...errorOptions);
        setOpen('error');
      } else
        errorOptions.forEach((error) => {
          setError(...error);
        });
    }
  };

  useEffect(() => {
    if (data) {
      const currentMenus = flatMenus(data.menus)
        .map(({ id }) => id)
        .join(',');
      setValue('menus', currentMenus);
      const currentUsers = (data?.users || []).map(({ id }) => id).join(',');
      setValue('users', currentUsers);
      setValue('enabled', data.enabled ? states[0].value : states[1].value);
    }
  }, [data, setValue]);

  const menus = useMemo(() => flatMenus(menusFetch), [menusFetch]);
  const loadingData = loadingMenus || loadingUsers || loadingRole;

  return (
    <Paper>
      {!loadingData ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={6}>
              <Input
                label="Name"
                fullWidth
                disabled
                value={data?.name || ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                disabled
                label="Code"
                fullWidth
                value={data?.code || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Status"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="menus"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Menus"
                      fullWidth
                      required
                      multiple
                      enableFilter
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.menus}
                      helperText={errors.menus && errors.menus.message}
                    >
                      {menus &&
                        menus.map(({ id, title }) => (
                          <MenuItem
                            key={id}
                            label={`${id}.${title}`}
                            value={`${id}`}
                          >
                            {title}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="users"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      label="Users"
                      fullWidth
                      multiple
                      enableFilter
                      maxItems={5}
                      value={field.value}
                      onChange={field.onChange}
                      error={!!errors.users}
                      helperText={errors.users && errors.users.message}
                    >
                      {users &&
                        users.map(({ id, username }) => (
                          <MenuItem
                            key={username}
                            label={`${username}`}
                            value={`${id}`}
                          >
                            {username}
                          </MenuItem>
                        ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/role"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'Role edited successfully'}
            {open === 'error' && 'An error occurred, please try again'}
          </SnackBar>
        </form>
      ) : (
        <CreateUserTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateUserTemplate;
