import styled from 'styled-components';

export const Table = styled.table<{ $height: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: inherit;
  border-collapse: collapse;
  border-spacing: 0px;
  min-width: 650px;
  // height: ${({ $height }) => 57 * $height + 25}px;
  position: relative;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TableCell = styled.td<{
  $flex?: string;
  $align?: 'flex-end' | 'center' | 'flex-start';
  $disabledBorder?: boolean;
}>`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  border-bottom: thin solid
    ${({ theme, $disabledBorder }) =>
      $disabledBorder ? 'transparent' : theme.palette.divider};
  text-align: left;
  padding: 16px;
  display: flex;
  align-items: center;
  flex: ${({ $flex }) => $flex || 1};
  justify-content: ${({ $align }) => $align};
`;

export const TableRow = styled.tr`
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0px;
  display: flex;
  flex-basis: 0%;
  flex-grow: 0;
`;

export const Svg = styled.svg`
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  margin-right: -28px;
`;
