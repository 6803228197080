import { Typography } from 'components/atoms';
import styled from 'styled-components';
import { lighten } from 'utils';

export const Subtitle = styled(Typography)`
  color: ${({ theme }) =>
    theme.isDarkMode ? 'inherit' : lighten(theme.palette.grey, 0.2)};
`;

export const Title = styled(Typography)`
  margin-bottom: 0px !important;
  margin-top: 4px !important;
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;
