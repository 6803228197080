import styled from 'styled-components';
import { darken, lighten } from 'utils';

const Check = styled.input`
  width: 20px;
  height: 20px;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${({ theme }) =>
    theme.isDarkMode ? 'transparent' : darken(theme.palette.divider, 0.5)};
  background-color: ${({ theme }) =>
    theme.isDarkMode ? lighten(theme.palette.background, 0.05) : 'transparent'};
  appearance: none;
  color-adjust: exact;
  border-radius: 4px;

  &:checked {
    background-color: ${({ theme }) => theme.palette.primary};
    border-color: ${({ theme }) => theme.palette.primary};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
`;

const CheckRoot = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  align-items: inherit;
  justify-content: inherit;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  color: var(--color);
  position: relative;
`;

export { Check, CheckRoot };
