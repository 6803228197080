import { object, ref, string, TypeOf, number } from 'yup';

export const CreateCustomerSchema = (customer: string) =>
  object({
    name: string().required(),
    domain: string().required(),
    welcome: string().required(),
    enabled: string().required(),
  });

// export type CreateUserType = TypeOf<typeof CreateUserSchema>;
export type CreateCustomerType = TypeOf<ReturnType<typeof CreateCustomerSchema>>;

// username: string()
// .required()
// .max(100)
// .email()
// .matches(
//   new RegExp(`.+(@${customer.replace('.', '\\.')})`, 'g'),
//   `Dominio invalido, debe terminar en @${customer}`,
// ),
// password: string().required().max(15),
// passwordConfirmation: string()
// .required()
// .oneOf([ref('password'), null], 'Las contraseñas no coinciden'),
// enabled: string().required(),
// roles: string().required(),
// error: string(),
