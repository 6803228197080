import {
  COMMON_OPTIONS,
  DATE_FORMAT_WITH_OUT_TIME,
  defaultStaticRanges,
} from 'const';
import { format, formatISO, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { useMenu } from 'hooks';
import { useMemo } from 'react';
import { DateRange, DefinedRange, Range } from 'react-date-range';
import { AiOutlineCalendar } from 'react-icons/ai';
import Input, { InputProps } from '../Input';
import Popover from '../Popover';

export type DateRangePickerValue = {
  startDate: string | number | Date;
  endDate: string | number | Date;
};

type customRange = Omit<
  Range,
  'color' | 'key' | 'autoFocus' | 'disabled' | 'showDateDisplay'
>;

export type DateRangePickerProps = {
  value: DateRangePickerValue;
  onChange: (value: customRange) => void;
} & Omit<
  InputProps,
  'onChange' | 'value' | 'endAdornment' | 'onClick' | 'readOnly'
>;

const DateRangePicker = ({
  value,
  onChange: onChangeProp,
  ...inputProps
}: DateRangePickerProps) => {
  const [anchor, handleOpen, handleClose] = useMenu<HTMLElement>();

  const newDate = useMemo(() => {
    return {
      startDate: new Date(value?.startDate),
      endDate: new Date(value?.endDate),
    };
  }, [value]);

  const onChange = ({ startDate, endDate }: Range) => {
    const startDays = startDate
      ? (formatISO(new Date(startDate)).split('T').shift() as string)
      : '';

    const startTime = value.startDate
      ? (formatISO(new Date(value.startDate)).split('T').pop() as string)
      : '';

    const endDays = endDate
      ? (formatISO(new Date(endDate)).split('T').shift() as string)
      : '';

    const endTime = value.endDate
      ? (formatISO(new Date(value.endDate)).split('T').pop() as string)
      : '';

    onChangeProp({
      startDate: parseISO(`${startDays}T${startTime}`) || undefined,
      endDate: parseISO(`${endDays}T${endTime}`) || undefined,
    });
  };

  const renderFormat = useMemo(() => {
    const startDate = value?.startDate
      ? format(
          new Date(value?.startDate),
          DATE_FORMAT_WITH_OUT_TIME,
          COMMON_OPTIONS,
        )
      : '';
    const endDate = value?.endDate
      ? format(
          new Date(value?.endDate),
          DATE_FORMAT_WITH_OUT_TIME,
          COMMON_OPTIONS,
        )
      : '';

    return `${startDate} - ${endDate}`;
  }, [value]);

  return (
    <>
      <Input
        readOnly
        endAdornment={
          <AiOutlineCalendar size="19" style={{ cursor: 'pointer' }} />
        }
        onClick={handleOpen}
        value={renderFormat}
        {...inputProps}
      />
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div style={{ display: 'flex' }}>
          <DefinedRange
            staticRanges={defaultStaticRanges}
            onChange={({ selection }) => {
              delete selection.key;
              onChange(selection);
            }}
            inputRanges={[]}
            ranges={[{ ...newDate, key: 'selection' }]}
          />
          <DateRange
            locale={es}
            editableDateInputs={false}
            onChange={({ selection }) => {
              delete selection.key;
              onChange(selection);
            }}
            moveRangeOnFirstSelection={false}
            ranges={[{ ...newDate, key: 'selection' }]}
            maxDate={new Date()}
          />
        </div>
      </Popover>
    </>
  );
};

export default DateRangePicker;
