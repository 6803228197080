import { Header } from 'components/molecules';
import { CreateOptionsTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administration' }];

const CreateOptionsPage = () => {
  return (
    <>
      <Header
        title="Option"
        subtitle="Create option"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateOptionsTemplate />
    </>
  );
};

export default withAuth(CreateOptionsPage);
