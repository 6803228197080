import mayasoft from 'assets/img/mayasoft.png';
import { Typography } from 'components/atoms';
import { useAuth, useTheme } from 'hooks';
import { HiChevronDown } from 'react-icons/hi';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Outlet } from 'react-router-dom';
import { AppBar, Navigator } from './components';
import {
  CollapseIcon,
  Container,
  CustomPerfectScrollbar,
  Footer,
  GridContainer,
  GridContent,
  GridMenu,
  Root,
} from './PrivateLayout.styles';

export type PrivateLayoutProps = {};

const PrivateLayout = ({ ...props }: PrivateLayoutProps) => {
  const { menu } = useAuth();
  const { menuIsOpen, toggleMenu } = useTheme();

  return (
    <Root {...props}>
      <AppBar />
      <GridContainer
        container
        alignItems="stretch"
        justify="center"
        wrap="nowrap"
      >
        <GridMenu item>
          <CollapseIcon
            variant="contained"
            $open={menuIsOpen}
            disabled={menu?.length === 0}
            onClick={toggleMenu}
          >
            <HiChevronDown size="22" />
          </CollapseIcon>
          <CustomPerfectScrollbar>
            {menu ? <Navigator options={menu} open={menuIsOpen} /> : <div />}
            <Footer $open={menuIsOpen}>
              <Typography variant="h3">Powered by</Typography>
              <img src={mayasoft} alt="Mayasoft icon" />
            </Footer>
          </CustomPerfectScrollbar>
        </GridMenu>
        <GridContent item xs>
          <PerfectScrollbar>
            <Container maxWidth="xl">
              <Outlet />
            </Container>
          </PerfectScrollbar>
        </GridContent>
      </GridContainer>
    </Root>
  );
};

export default PrivateLayout;
