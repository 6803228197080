import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  Input,
  SnackBar,
  Typography,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { Table } from 'components/organisms';
import { DELETE_ROLE, UPDATE_ROLE_STATE } from 'const';
import { useDialog, useGetTable } from 'hooks';
import useTable, { filterModelType, tableModelType } from 'hooks/useTable';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoAdd } from 'react-icons/io5';
import { PaginationType, Role } from 'types';
import { createFilterStructure } from 'utils';
import {
  defaultListRoleTemplateFilter,
  defaultPagination,
  defaultSortModel,
  fetchProcess,
  getColumns,
} from './ListRoleTemplate.const';
import {
  ListRoleTemplateFilterSchema,
  ListRoleTemplateFilterType,
} from './ListRoleTemplate.schema';
import { ListRoleTemplateProps } from './ListRoleTemplate.types';

const ListRoleTemplate = ({ ...props }: ListRoleTemplateProps) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ListRoleTemplateFilterType>({
    defaultValues: defaultListRoleTemplateFilter,
    resolver: yupResolver(ListRoleTemplateFilterSchema),
  });

  const [selected, onOpen, onCloseModal] = useDialog<number | undefined>(
    undefined,
  );
  const [action, setAction] = useState<'remove' | 'edit' | undefined>(
    undefined,
  );
  const [open, setOpen] = useState<severityType>();

  const confirmEdit = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('edit');
    },
    [onOpen],
  );

  const confirmRemove = useCallback(
    (id: number) => {
      onOpen(id);
      setAction('remove');
    },
    [onOpen],
  );

  const onCloseSnackBar = useCallback(() => {
    setAction(undefined);
    setOpen(undefined);
  }, []);

  const onClose = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  const columns = useMemo(
    () => getColumns(confirmRemove, confirmEdit),
    [confirmEdit, confirmRemove],
  );

  const [filterModel, setFilterModel] = useState<filterModelType<Role>[]>([]);

  const {
    loading,
    error,
    data: process,
    handleData,
    getData,
    mutate,
  } = useGetTable<PaginationType<Role>, tableModelType<Role>>(fetchProcess, {
    paginationModel: defaultPagination,
    sortModel: defaultSortModel,
    filterModel,
  });

  const tableControllers = useTable<Role>(
    {
      filterModel,
      paginationModel: process
        ? { ...defaultPagination, rowsCount: process.rowsCount }
        : defaultPagination,
    },
    handleData,
    getData as (
      param: Omit<tableModelType<Role>, 'paginationModel'>,
    ) => Promise<PaginationType<Role>>,
  );

  const removeRole = async (idRole: number) => {
    try {
      setLoadingForm(true);
      await axios.delete(DELETE_ROLE.replace(':id', idRole.toString()));
      await handleData({
        paginationModel: tableControllers.paginationModel,
        sortModel: tableControllers.sortModel,
        filterModel,
      });
      onClose();
      setLoadingForm(false);
      setOpen('success');
    } catch (error) {
      onClose();
      setLoadingForm(false);
      setOpen('error');
    }
  };

  const editRole = async (idRole: number) => {
    try {
      setLoadingForm(true);

      await axios.put(UPDATE_ROLE_STATE.replace(':id', idRole.toString()));
      onClose();
      mutate((before) => {
        const result = [...before.result];
        const index = result.findIndex(({ id }) => id === idRole);
        result[index].enabled = !result[index].enabled;
        return { ...before, result };
      });
      setLoadingForm(false);
      setOpen('success');
    } catch (error) {
      onClose();
      setLoadingForm(false);
      setOpen('error');
    }
  };

  const onSubmit = useCallback(
    (filterListRoleTemplate: ListRoleTemplateFilterType) => {
      const filterRolename = createFilterStructure(
        'name',
        'contains',
        filterListRoleTemplate.name,
      );

      const modelFilter = [filterRolename].filter((item) => item);
      setFilterModel(modelFilter as filterModelType<Role>[]);
    },
    [],
  );

  const selectedRole = useMemo(
    () =>
      selected !== undefined
        ? process?.result.find((user) => user.id === selected)
        : undefined,
    [selected, process],
  );

  return (
    <>
      <Table<Role>
        {...tableControllers}
        numberOfVisibleColumns={4}
        title={`We found ${tableControllers.paginationModel.rowsCount} roles`}
        columns={columns}
        rows={process?.result || []}
        error={!!error}
        loading={loading}
        filterComponent={
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            spacing={4}
            wrap="nowrap"
          >
            <Grid item>
              <IconButton
                variant="contained"
                href="/pages/management/role/create"
                info="Crear role"
              >
                <IoAdd />
              </IconButton>
            </Grid>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Grid item>
                <Grid
                  container
                  alignItems="center"
                  justify="flex-end"
                  spacing={4}
                  wrap="nowrap"
                >
                  <Grid item xs={10}>
                    <Input
                      fullWidth
                      placeholder="Search by name"
                      {...register('name')}
                      error={!!errors.name}
                      helperText={errors.name && errors.name.message}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      isSmall
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        }
      />
      <Dialog
        open={selected !== undefined}
        onClose={onClose}
        title="Confirm this action"
      >
        <Grid container spacing={3} justify="flex-end">
          <Grid item xs={12}>
            {action === 'remove' && (
              <Typography variant="h2">
                {`¿Estas seguro de eliminar el rol ${selectedRole?.name}?`}
              </Typography>
            )}
            {action === 'edit' && (
              <Typography variant="h2">
                {`Are you sure to delete the role ${selectedRole?.name}?`}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Button variant="contained" color="grey" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            {action === 'remove' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  removeRole(selectedRole?.id ? selectedRole?.id : 0)
                }
                loading={loadingForm}
              >
                Yes, I want to delete it
              </Button>
            )}

            {action === 'edit' && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  editRole(selectedRole?.id ? selectedRole?.id : 0)
                }
                loading={loadingForm}
              >
                Yes, I want to change it
              </Button>
            )}
          </Grid>
        </Grid>
      </Dialog>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onCloseSnackBar}
        severity={open}
      >
        {open === 'success' &&
          action === 'edit' &&
          'Edit user status successfully'}
        {open === 'success' &&
          action === 'remove' &&
          'We successfully deleted the use'}
        {open === 'error' && 'An error occurred, please try again'}
      </SnackBar>
    </>
  );
};

export default ListRoleTemplate;
