import axios, { AxiosRequestConfig } from 'axios';
import { GET_ROLES, GET_CUSTOMER } from 'const';
import { Transaction, CustomerOption } from 'types';

export const fetchRoles = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_ROLES, config);
};

export const fetchCustomer = ({ id }: any, config?: AxiosRequestConfig<CustomerOption>) => {
  return axios.get<CustomerOption>(`${GET_CUSTOMER.replace(':id', id)}`, config);
};
