import { Header } from 'components/molecules';
import { CreateUserTemplate } from 'components/templates';
import { withAuth } from 'HOCs';

const breadcrumbs = [{ label: 'Administration' }];

const CreateUserPage = () => {
  return (
    <>
      <Header
        title="User"
        subtitle="Create user"
        breadCrumbsProps={{ breadcrumbs: breadcrumbs }}
      />
      <CreateUserTemplate />
    </>
  );
};

export default withAuth(CreateUserPage);
