import axios from 'axios';
import {
  Button,
  Grid,
  Input,
  InputPassword,
  MenuItem,
  Paper,
  Select,
  SnackBar,
} from 'components/atoms';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { CREATE_OPTIONS, CREATE_USER, CREATE_CUSTOMER } from 'const';
import { states } from 'const/const';
import { useCustomerContext } from 'contexts';
import { useGetTable, useYupResolve } from 'hooks';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomerOption, Role, User, TypeOption, Option } from 'types';
import { getErrorsFromBackend } from 'utils';
import {
  fetchRoles,
} from './CreateCustomerTemplate.const';
import CreateCustomerTemplateLoading from './CreateCustomerTemplate.loading';
import {
  CreateCustomerSchema,
  CreateCustomerType,
} from './CreateCustomerTemplate.schema';

const CreateCustomerTemplate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { domain } = useCustomerContext();
  

  const { data: roles, loading: loadingRoles } = useGetTable<Role[]>(
    fetchRoles,
    [{}],
  );



  const validation = useCallback(
    async (data: any) => {
      const currentSchema = CreateCustomerSchema(domain);

      const values = await currentSchema.validate(data, {
        abortEarly: false,
      });

      return values;
    },
    [domain],
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: { errors },
  } = useForm<CreateCustomerType>({
    resolver: useYupResolve(validation),
    defaultValues: {
      enabled: 'true',
    },
  });

  const [open, setOpen] = useState<severityType>();

  const onClose = () => {
    setOpen(undefined);
    if (open === 'success') navigate('/pages/management/customer');
  };

  const onSubmit = async (createCustomerForm: CreateCustomerType) => {
    try {
      setLoading(true);
      const { ...form } = createCustomerForm;
      //   const plainRoles = roles?.split(',').filter((item) => item !== '');
      const enabled = form.enabled === 'true';
      await axios.post<CustomerOption>(CREATE_CUSTOMER, {
        ...form,
        enabled,
        // roles: plainRoles,
      });
      setLoading(false);
      setOpen('success');
    } catch (error) {
      setLoading(false);
      //   const { isGeneral, errorOptions } = getErrorsFromBackend<CreateOptionsType>(
      //     error,
      //     {
      //       username: {
      //         Unique: 'Este usuario ya existe, pruebe con otro nuevamente',
      //       },
      //     },
      //   );

      //   if (isGeneral) {
      //     setError(...errorOptions);
      //     setOpen('error');
      //   } else
      //     errorOptions.forEach((error) => {
      //       setError(...error);
      //     });
    }
  };
  return (
    <Paper>
      {!loadingRoles ? (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justify="flex-end">
            <Grid item xs={2}>
              <Input
                label="Name"
                fullWidth
                required
                {...register('name')}
                // error={!!errors.name}
                // helperText={errors.name ? errors.name.message : `Error`}
              />
            </Grid>
            <Grid item xs={4}>
              <Input
                label="Domain"
                fullWidth
                required
                {...register('domain')}
                // error={!!errors.domain}
                // helperText={errors.domain ? errors.domain.message : `Error`}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="enabled"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      required
                      value={field.value}
                      onChange={field.onChange}
                      label="Status"
                      fullWidth
                    >
                      {states.map(({ value, name }) => (
                        <MenuItem
                          key={name}
                          label={`${name}`}
                          value={`${value}`}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label="Welcome Message"
                fullWidth
                required
                {...register('welcome')}
                // error={!!errors.domain}
                // helperText={errors.domain ? errors.domain.message : `Error`}
              />
            </Grid>
         
           
            <Grid item>
              <Button
                fullWidth
                variant="text"
                color="grey"
                href="/pages/management/customer"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                loading={loading}
              >
                Create
              </Button>
            </Grid>
          </Grid>
          <SnackBar
            wait={2000}
            open={open !== undefined}
            onClose={onClose}
            severity={open}
          >
            {open === 'success' && 'The customer was created successfully'}
            {open === 'error' && 'An error occurred, please try again'}
          </SnackBar>
        </form>
      ) : (
        <CreateCustomerTemplateLoading />
      )}
    </Paper>
  );
};

export default CreateCustomerTemplate;
