import 'config/axios';
import 'config/yup';
import { PrivateLayout, PublicLayout } from 'layouts';
import {
  ChangePasswordPage,
  CreateOptionsPage,
  CreateRolePage,
  CreateUserPage,
  EditOptionsPage,
  EditRolePage,
  EditUserPage,
  HomePage,
  ListOptionsPage,
  ListRolePage,
  ListUserPage,
  LoginPage,
  TransactionPageReport,
} from 'pages';
import { ListAwsPage } from 'pages/AwsPage';
import CreateAwsPage from 'pages/AwsPage/CreateAwsPage';
import EditAwsPage from 'pages/AwsPage/EditAwsPage';
import { ListCustomerPage } from 'pages/CustomerPage';
import CreateCustomerPage from 'pages/CustomerPage/CreateCustomerPage';
import EditCustomerPage from 'pages/CustomerPage/EditCustomerPage';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Navigate, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="dashboard" element={<Navigate to="/pages/home" />} />
      <Route path="/" element={<PublicLayout />}>
        <Route index element={<Navigate to="/login" />} />
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/dashboard" />} />
      <Route path="pages" element={<PrivateLayout />}>
        <Route index />
        <Route path="home" element={<HomePage />} />

        <Route path="account">
          <Route index element={<ListUserPage />} />
          <Route path="change_password" element={<ChangePasswordPage />} />
        </Route>

        {/* para agregar el update */}
        <Route path="management">
          <Route path="user">
            <Route index element={<ListUserPage />} />
            <Route path="create" element={<CreateUserPage />} />
            <Route path=":id/edit" element={<EditUserPage />} />
          </Route>
          <Route path="option">
            <Route index element={<ListOptionsPage />} />
            <Route path="create" element={<CreateOptionsPage />} />
            <Route path=":id/edit" element={<EditOptionsPage />} />
          </Route>
          <Route path="role">
            <Route index element={<ListRolePage />} />
            <Route path="create" element={<CreateRolePage />} />
            <Route path=":id/edit" element={<EditRolePage />} />
          </Route>
          <Route path="customer">
            <Route index element={<ListCustomerPage />} />
            <Route path="create" element={<CreateCustomerPage />} />
            <Route path=":id/edit" element={<EditCustomerPage />} />
          </Route>
          <Route path="aws">
            <Route index element={<ListAwsPage />} />
            <Route path="create" element={<CreateAwsPage />} />
            <Route path=":id/edit" element={<EditAwsPage />} />
          </Route>
        </Route>
        {/* <Route path="management">
       
        </Route> */}

        <Route path="process">
          <Route path=":id" element={<TransactionPageReport />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
