import axios, { AxiosRequestConfig } from 'axios';
import { GET_ROLES, GET_CUSTOMERS, GET_TYPEOPTIONS, GET_OPTIONS,GET_OPTION } from 'const';
import { Option, Transaction } from 'types';

export const fetchRoles = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_ROLES, config);
};

export const fetchCustomers = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_CUSTOMERS, config);
};

export const fetchTypeOptions = <T extends object[]>(
  data: any,
  config?: AxiosRequestConfig<Transaction<T>>,
) => {
  return axios.get<T>(GET_TYPEOPTIONS, config);
};

export const fetchOptions = ({ id }: any, config?: AxiosRequestConfig<Option>) => {
  return axios.get<Option>(`${GET_OPTION.replace(':id', id)}`, config);
};
