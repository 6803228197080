import Typography from '../Typography';
import { Content, ContentProps, Root } from './Tooltip.styles';

export type TooltipProps = {
  message: string;
  position?: ContentProps['$position'];
} & React.HTMLAttributes<HTMLDivElement>;

const Tooltip = ({
  children,
  message,
  position = 'left',
  ...props
}: TooltipProps) => {
  return (
    <Root {...props}>
      {children}
      <Content $position={position}>
        <Typography variant="p1" color="white" wrap={false}>
          {message}
        </Typography>
      </Content>
    </Root>
  );
};

export default Tooltip;
