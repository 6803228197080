import { object, ref, string, TypeOf, number } from 'yup';

export const EditOptionsSchema = object({
  customer: string().required(),
  typeOption: string().required(),
  order: number().required(),
  message: string().required().max(250),
  value: string().required().max(250),
  attempt: number().required(),
  enabled: string().required(),
});

// export type CreateUserType = TypeOf<typeof CreateUserSchema>;
export type EditOptionsType = TypeOf<typeof EditOptionsSchema>;

// username: string()
// .required()
// .max(100)
// .email()
// .matches(
//   new RegExp(`.+(@${customer.replace('.', '\\.')})`, 'g'),
//   `Dominio invalido, debe terminar en @${customer}`,
// ),
// password: string().required().max(15),
// passwordConfirmation: string()
// .required()
// .oneOf([ref('password'), null], 'Las contraseñas no coinciden'),
// enabled: string().required(),
// roles: string().required(),
// error: string(),
