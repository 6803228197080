import { Alert, Grid, SnackBar } from 'components/atoms';
import { Intro, Title } from './HomeTemplate.styles';

import icon from 'assets/img/icon.png';
import axios from 'axios';
import { severityType } from 'components/atoms/Alert/Alert.styles';
import { REQUEST_PERMISSIONS } from 'const';
import { useAuth } from 'hooks';
import { useState } from 'react';

const HomeTemplate = () => {
  const { menu } = useAuth();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState<severityType>();
  const [success, setSuccess] = useState(false);

  const onClose = () => {
    setOpen(undefined);
  };

  // const requestPermissions = async () => {
  //   setLoading(true);
  //   try {
  //     await axios.post(REQUEST_PERMISSIONS);
  //     setOpen('success');
  //     setSuccess(true);
  //   } catch (error) {
  //     setSuccess(false);
  //     setOpen('error');
  //   }
  //   setLoading(false);
  // };

  return (
    <>
      <Grid
        container
        spacing={5}
        alignItems="center"
        style={{ height: '100%' }}
        justify="center"
      >
        <Grid item>
          <Intro variant="h2">Welcome to</Intro>
          <Title variant="h1">Robbie Portal</Title>
          {!menu ||
            (menu.length === 0 && (
              <Alert
                severity={success ? 'info' : 'warning'}
                action={success ? undefined : 'Solicita permisos'}
                // buttonProps={{ loading, onClick: requestPermissions }}
              >
                {success
                  ? 'You will be notified by email when permissions have been assigned to you.'
                  : 'You currently do not have any permissions, request permissions from the administrators.'}
              </Alert>
            ))}
        </Grid>
        <Grid item xs={3} sm={8} md={6} lg={3}>
          <img src={icon} alt="test" width="100%" />
        </Grid>
      </Grid>
      <SnackBar
        wait={2000}
        open={open !== undefined}
        onClose={onClose}
        severity={open}
      >
        {open === 'success' &&
          'Administrators were asked to assign permissions.'}
        {open === 'error' && 'An error occurred, please try again.'}
      </SnackBar>
    </>
  );
};

export default HomeTemplate;
